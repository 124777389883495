import React, { useEffect, useRef, useState } from "react";
import "../Invester/InvesterEdit.css";
import axios from "axios";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import debounce from "lodash.debounce";

const EditMessageBlog = () => {
  const editor = useRef(null);
  const navigate = useNavigate();
  const [turkish, setTurkish] = useState("");
  const [frenc, setFrenc] = useState("");
  const [english, setEnglish] = useState("");
  const [portuguese, setPortuguese] = useState("");
  const [swahili, setSwahili] = useState("");
  const [spanish, setSpanish] = useState("");
  const [description1, setDescription1] = useState("");
  const [description2, setDescription2] = useState("");
  const [description3, setDescription3] = useState("");
  const [description4, setDescription4] = useState("");
  const [description5, setDescription5] = useState("");
  const [description6, setDescription6] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");
  const route = useLocation();
  const InvesterData = route?.state?.item;
  const [Detail, setDetail] = useState([]);
  // getCountry API Function...............................................
  const MyToken = localStorage.getItem("TOKEN");
      const config = {
    readonly: false,
    toolbar: true,
    controls: {
      font: {
        list: {
          Cormorant: "Cormorant",
          Montserrat: "Montserrat",
        },
        default: "Montserrat",
      },
    },
    buttons: [
      'bold', 'italic', 'underline', 'link', '|', 'videoButton', 'imageButton', '|', 'ul', 'ol', 'font', 'fontsize', 'brush', 'paragraph'
    ],
    extraButtons: [
      {
        name: 'videoButton',
        iconURL: 'https://cdn-icons-png.flaticon.com/512/725/725149.png', // Video button icon
        tooltip: 'Insert Video',
        exec: (editor) => {
          const videoUrl = prompt('Enter the video URL');
          if (videoUrl) { 
            const videoEmbed = `<iframe src="${videoUrl}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
            editor.selection.insertHTML(videoEmbed);
          }
        },
      },
      {
        name: 'imageButton',
        iconURL: 'https://cdn-icons-png.flaticon.com/512/860/860790.png', // Image button icon
        tooltip: 'Insert Image',
        exec: async (editor) => {
          const fileInput = document.createElement('input');
          fileInput.type = 'file';
          fileInput.accept = 'image/*';
          fileInput.onchange = async () => {
            const file = fileInput.files[0];
            if (file) {
              const reader = new FileReader();
              reader.onload = () => {
                const imageEmbed = `<img src="${reader.result}" alt="Uploaded Image" style="max-width: 100%;" />`;
                editor.selection.insertHTML(imageEmbed);
              };
              reader.readAsDataURL(file);
            }
          };
          fileInput.click();
        },
      },
    ],
  };
  const initialvalues = {
    Turkish: "",
    Frenc: "",
    English: "",
    Portuguese: "",
    Swahili: "",
    Spanish: "",
   Description1:"",
   Description2:"",
   Description3:"",
   Description4:"",
   Description5:"",
   Description6:"",
  };

  const onSubmit = async () => {
    try {
      const res = await axios.post(
        "https://api.turkafrica.app/admin/v1/blog/edit-blog-msg",
          {
          id : InvesterData?.id.toString(),
          title_turkish: turkish,
          title_french: frenc,
          title_portuguese: portuguese,
          title_swahili: swahili,
          title_spanish: spanish,
          title_english: english,
          description_turkish: description1,
          description_french: description2,
          description_portuguese: description3,
          description_swahili: description4,
          description_spanish: description5,
          description_english: description6,
        },
        {
          headers: {
            Authorization: `Bearer ${MyToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      navigate("/MessageBlog")
      toast.success(res.data.message);
    } catch (error) {
      toast.error(res.data.message);
    }
  };
  const { handleSubmit } = useFormik({
    initialValues: initialvalues,
    onSubmit,
  });

    const handleFileChange = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    uploadimg(formData);
  };
  const uploadimg = async (formData) => {
      try {
        const res = await axios.post(
          "https://api.turkafrica.app/admin/v1/common/uploadImage",
          formData,
          {
            headers: {
              Authorization: `Bearer ${MyToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setPreviewUrl(res.data.data.image_url);
      } catch (error) {
      }
  };
  
   const handleBlur = debounce((newContent) => {
  setDescription1(newContent);
}, 300);
   const handleBlur1 = debounce((newContent) => {
  setDescription2(newContent);
}, 300);
   const handleBlur2 = debounce((newContent) => {
  setDescription3(newContent);
}, 300);
   const handleBlur3 = debounce((newContent) => {
  setDescription4(newContent);
}, 300);
   const handleBlur4 = debounce((newContent) => {
  setDescription5(newContent);
}, 300);
   const handleBlur5 = debounce((newContent) => {
  setDescription6(newContent);
   }, 300);
//     const getBlogs = async () => {
//     try {
//       const res = await axios.post(
//         "https://api.turkafrica.app/admin/v1/blog/get-detail",
//         {
//             id: InvesterData?.id.toString(),
//             type: "1"
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${MyToken}`,
//           },
//         }
//       );
//       setDetail(res.data.data);
//     } catch (error) {
//       // console.log(error);
//     }
//   };
//   useEffect(() => {
//     getBlogs();
//   }, [])
    
     useEffect(() => {
        if (InvesterData) {
           setTurkish(InvesterData?.title_turkish);
            setDescription1(InvesterData?.description_turkish)
            setFrenc(InvesterData?.title_french)
            setDescription2(InvesterData?.description_french)
            setPortuguese(InvesterData?.title_portuguese)
            setSwahili(InvesterData?.title_swahili)
            setSpanish(InvesterData?.title_spanish)
            setEnglish(InvesterData?.title_english)
            setDescription3(InvesterData?.description_portuguese)
            setDescription4(InvesterData?.description_swahili)
            setDescription5(InvesterData?.description_spanish)
          setDescription6(InvesterData?.description_english)
        }
      }, [InvesterData]);
  return (
    <div className="new-add-important-flex">
      <div className="Main-Content-class" style={{display:"flex",alignItems:'flex-start'}}>
        <div
          className="page-content"
          style={{ marginTop: "2rem", alignItems: "flex-end" }}
        >
          <div
            className="Category-product"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="three-ones">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style={{ color: "#0a58ca" }}
              >
                <path
                  fill="currentColor"
                  d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59l7-7l7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z"
                />
              </svg>
              <a
                style={{
                  color: "#008cff",
                  fontWeight: "500",
                  fontSize: "16px",
                  textDecoration: "none",
                }}
                href=""
              >
                EditBlog
              </a>
              <span>Blog</span>
            </div>
          </div>
          <hr className="hr-tag-class" />
        </div>
        <form className="" onSubmit={handleSubmit}>
          <div className="main-Blos-for-edits">
            <div className="gap-name-input-edit">
              <span className="span-name-invester">Title Turkish</span>
              <input
                type="text"
                name="turkish"
                className="form-control title-add-langues"
                value={turkish}
                onChange={(e) => {
                   setTurkish(e.target.value);
                }}  
              />
                      </div>
                      <div className="gap-name-input-edit">
                                       <span className="span-name-invester">Description Turkish</span>
 
                          <JoditEditor
                        required
                        ref={editor}
                        className="title-add-langues"
                        config={config}
                tabIndex={1}
                name="description1"
                value={description1}
                onBlur={handleBlur}
                      />
                      </div>
                       
            <div className="gap-name-input-edit">
              <span className="span-name-invester">Title French</span>
              <input
                type="text"
                name="frenc"
                value={frenc}
                className="form-control title-add-langues"
                 onChange={(e) => {
                   setFrenc(e.target.value);
                }}  
              />
                      </div>
                       <div className="gap-name-input-edit">
                                       <span className="span-name-invester">Description French</span>
                          <JoditEditor
                        required
                        ref={editor}
                        className="title-add-langues"
                        config={config}
                        tabIndex={1}
                        name="description2"
                        value={description2}
                        onBlur={handleBlur1}
                      />
                      </div>
         
            <div className="gap-name-input-edit">
              <span className="span-name-invester">Title Portuguese</span>
              <input
                type="text"
                name="portuguese"
                className="form-control title-add-langues"
                value={portuguese}
                  onChange={(e) => {
                   setPortuguese(e.target.value);
                }}  
              />
                      </div>
                       <div className="gap-name-input-edit">
                        <span className="span-name-invester">Description Portuguese</span>
                         <JoditEditor
                        required
                        ref={editor}
                        className="title-add-langues"
                        config={config}
                        tabIndex={1}
                        name="description3"
                        value={description3}
                        onBlur={handleBlur2}
                      />
                      </div>
            <div className="gap-name-input-edit">
              <span className="span-name-invester">Title Swahili</span>
              <input
                type="text"
                name="swahili"
                className="form-control title-add-langues"
                value={swahili}
               onChange={(e) => {
                   setSwahili(e.target.value);
                }}  
              />
                      </div>
                       <div className="gap-name-input-edit">
                                       <span className="span-name-invester">Description Swahili</span>
 
                        <JoditEditor
                        required
                        ref={editor}
                        className="title-add-langues"
                        config={config}
                        tabIndex={1}
                        name="description4"
                        value={description4}
                        onBlur={handleBlur3}
                      />
                      </div>
            <div className="gap-name-input-edit">
              <span className="span-name-invester">Title Spanish</span>
              <input
                type="text"
                name="spanish"
                className="form-control title-add-langues"
                value={spanish}
                 onChange={(e) => {
                   setSpanish(e.target.value);
                }}  
              />
            </div>
            
                       <div className="gap-name-input-edit">
                                       <span className="span-name-invester">Description Spanish</span>
  <JoditEditor
                        required
                        ref={editor}
                        className="title-add-langues"
                        config={config}
                        tabIndex={1}
                        name="description5"
                        value={description5}
                        onBlur={handleBlur4}
                      />
            </div>
               <div className="gap-name-input-edit">
              <span className="span-name-invester">Title English</span>
              <input
                type="text"
                name="english"
                className="form-control title-add-langues"
                value={english}
                onChange={(e) => {
                   setEnglish(e.target.value);
                }}  
              />
            </div>
           <div className="gap-name-input-edit">
                                       <span className="span-name-invester">Description English</span>
 
                           <JoditEditor
                        required
                        ref={editor}
                        className="title-add-langues"
                        config={config}
                        tabIndex={1}
                        name="description6"
                        value={description6}
                        onBlur={handleBlur5}
                      />
                      </div>
          </div>
          <button type="submit" className="update-btn-inexter">
            Update Blog
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditMessageBlog;
